<script setup>
import { useRoute } from 'vue-router'

const route = useRoute()
</script>

<template>
  <div
    class="se"
    :class="route.query?.brand ?? null"
  >
    <router-view />
  </div>
</template>
