import { defineAsyncComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

const EmptyRouterView = defineAsyncComponent(() =>
  import('@/views/Empty/index.vue')
)
const Vm = () => import('@/views/ScreenVm/index.vue')
const Microsite = defineAsyncComponent(() =>
  import('@/views/Microsite/index.vue')
)

const routes = [
  {
    path: '/',
    name: 'root',
    children: [
      {
        path: 'vm/:deviceId',
        name: 'vm',
        component: Vm,
        props: (route) => ({ deviceId: route.params.deviceId })
      },
      {
        path: ':token',
        name: 'microsite',
        component: Microsite,
        props: (route) => ({ token: route.params.token })
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: EmptyRouterView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { left: 0, top: 0 }
  }
})

export default router
