import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createLogger } from 'vue-logger-plugin'
import VueDOMPurifyHTML from 'vue-dompurify-html'

import App from './App.vue'
import router from './router'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/styles/main.scss'

const isProduction = import.meta.env.VITE_APP_MODE === 'production'
const isDev = import.meta.env.VITE_APP_MODE === 'development'

const app = createApp(App)

const options = {
  enabled: true,
  level: isProduction ? 'error' : 'debug'
}

// Register the service worker when in production mode
if ('serviceWorker' in navigator && !isDev) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js', { scope: '/' })
  })
}

app
  .use(createPinia())
  .use(router)
  .use(Antd)
  .use(VueDOMPurifyHTML)
  .use(createLogger(), options)
  .mount('#app')
